<template>
  <div class="authentication-detail">
    <app-detail :opt="opt"></app-detail>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      opt: {
        title: "认证记录详情",
        form: [
          {
            label: "姓名",
            key: "name",
            isCol50: true
          },
          {
            label: "性别",
            key: "gender",
            isCol50: true
          },
          {
            label: "地址",
            key: "address"
          },
          {
            label: "身份证号",
            key: "idNo",
            isCol50: true
          },
          // {
          //   label: "电话",
          //   key: "phone",
          //   isCol50: true
          // },
          // {
          //   label: "紧急联系人",
          //   key: "emergency_contract",
          //   isCol50: true
          // },
          // {
          //   label: "紧急联系电话",
          //   key: "emergency_phone_num",
          //   isCol50: true
          // },
          // {
          //   label: "入住时间",
          //   key: "startDate",
          //   isCol50: true
          // },
          // {
          //   label: "结束时间",
          //   key: "endDate",
          //   isCol50: true
          // },
          // {
          //   label: "入住房间",
          //   key: "roomNo",
          //   isCol50: true
          // },
          // {
          //   label: "入住小区",
          //   key: "apartmentName",
          //   isCol50: true
          // },
          // {
          //   label: "房客类型",
          //   key: "talentTypeStr",
          //   isCol50: true
          // },
          {
            label: "用户类型",
            key: "userType",
            isCol50: true
          },
          {
            label: "认证时间",
            key: "authTime",
            isCol50: true
          },
          // {
          //   label: "识别进度",
          //   key: "recognizeResult",
          //   isCol50: true
          // },
          {
            label: "人证机SN",
            key: "deviceId",
            isCol50: true
          },
          {
            label: "认证结果",
            key: "authRstStr",
            isCol50: true
          },
          // {
          //   label: "人证机IP",
          //   key: "ip",
          //   isCol50: true
          // },
          //   {
          //     label: "身份证头像",
          //     key: "idPhotoUrl",
          //     type: "img"
          //   },
          {
            label: "现场比对照片",
            key: "authPicUrl",
            type: "img"
          }
        ],
        get(opt) {
          let dto = {
            recordId: opt.id
          };
          _this
            .post("/device-service/rzjmanagment/getAuthRecordByRecordId", dto)
            .then(data => {
              data.authRstStr = ["成功", "失败"][data.authRst - 1];
              data.idNo =data.idNo?
                data.idNo.substring(0, 2) +
                "******" +
                data.idNo.substring(data.idNo.length - 2, data.idNo.length):'';
              data.userType = [
                "系统管理员",
                "房东",
                "工程人员",
                "财务",
                "房客",
                "房管员",
                "",
                "",
                "",
                "未知人员"
              ][data.userType - 1];
              opt.cb(data);
            });
        }
      }
    };
  },
  created: function() {
    console.log("sale-detail created!!");
  }
};
</script>

